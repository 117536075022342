@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.homer-header {
  height: 500px;
}
.follower-mouse {
  position: fixed;
  width: 30px;
  height: 30px;
  background-color: rgba(98, 245, 44, 0.411);
  border-radius: 50%;
  pointer-events: none;
  display: grid;
  place-content: center;
  z-index: 9999;
  margin-bottom: 100px;
}
.follows {
  background-color: rgb(98, 245, 44);
  width: 10px;
  border-radius: 50%;
  height: 10px;
  z-index: 9999;
}

#map {
  width: 100%;
  height: 100vh;
  z-index: 1;
  
  border: 1.4px solid gray;
}
.map-container{
  width: 90vw;
  margin: auto;
  height: 100vh;
}
.map-project{
  z-index: 10000000;
  position: absolute;
  bottom: 0;
  height: 80%;
  width: 400px;
  margin: 5px;
}
.open-project{
  z-index: 10000;
  position: absolute;
  bottom: 0;
  height: 30px;
  width: 30px;
  margin: 5px;
  background-color: white;
  display: grid;
  place-content: center;
}
.project-item-icon{
  height: 15px;
  width: 15px;
  border-radius: 3px;  
}
.project-item{
  display: flex;
  background: #f1f1f1;
  padding: 5px 0px;
  margin: 4px 0px;
  cursor: pointer;
  border-radius: 3px;
  border: solid 1px rgb(232, 231, 231);
}
.item-title-project{
  font-weight: 700;
}
.item-sub-title-project{
  color: #747272;
  font-weight: 400;
}
.leaflet-control-attribution.leaflet-control {
  display: none;
}

